import React from "react"
import Footer from "../components/footer"
import ContentBlock from "../components/contentblock"

export default function Home() {
  return (

    <main>
          <h1>Freelance Web Developer in Brighton</h1>

    <ContentBlock
    id="about"
    >
    <p>Hello, I'm Rachel and I code.</p>
     <p>I am no longer taking on any new projects or contracts. If you are a previous client and need assistance with an old project, please <a href="mailto:hello@rachel.codes">shoot me an email</a>.</p>
    </ContentBlock>





        <Footer icon_credits="Icon credits: Night Mode by Ravindra Kalkani from the Noun Project" />

    </main>
  );
}
